.hotel-images {
  position: relative;
  width: 100%;
  height: auto;
}

.card-bottom-info {
  width: 100%;
  padding: 20px;
  line-height: 20px;
}

.hotel-text {
  margin-bottom: 20px;
  line-height: 25px;
  text-align: justify;
}

.location-container {
  height: 30px;
  width: 300px;
  overflow: hidden;
  border-radius: 5px;
}

.location-text-container {  
  height: 100%;
  margin: 0px;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgb(235, 235, 235);
}

.rooms-price-container {
  padding-top: 10px;
}

.rooms-container {
  height: 30px;
  width: 155px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 5px;
}

.icon-box {
  width: 30px;
  height: 100%;
  background-color: #00296b;
}

.price-container {
  width: 70px;
  height: 30px;
  border-radius: 5px;
  background-color: #00296b;
}

.dollar-icons {
  padding: 2px;
}

.btn-container {
  display: flex;
  width: 100%;
  height: 60px;
}

.btn-book {
  width: 100%;
  border: none;
  background-color: #fdc500;
  font-size: 18px;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.btn-book:hover {
  background-color: #00296b;
  color: #f7c342;
}

.btn-book:focus {
  outline: white;
  box-shadow: 0px 0px 7px 0px rgb(19, 165, 190);
}

/* DEKSTOP VERSION */

@media screen and (min-width: 768px) {

  .card-container {
    overflow: hidden;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 2px 18px 0px rgba(50, 50, 50, 0.23);
  }

}

/* MOBILE VERSION */

@media screen and (min-width: 320px) and (max-width: 767px) {

  .card-container {
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 1px 2px 18px 0px rgba(50, 50, 50, 0.23);
  }  

}