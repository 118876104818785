.filter-icons {
  position: absolute;
  margin-left: 15px;
  color: rgb(248, 248, 248, 0.7);
}

.date-container-from:before {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  border-radius: 5px;
  content: '\f2f6';
  font-family: "Font Awesome 5 Pro";
  font-size: 15px;
  color: rgba(221, 221, 221, 0.953);
  pointer-events: none;
}

.date-container-to:before {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  border-radius: 5px;
  content: '\f2f5';
  font-family: "Font Awesome 5 Pro";
  font-size: 15px;
  color: rgba(221, 221, 221, 0.953);
  pointer-events: none;
}

::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: -20px;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer; 
}


.date-styles:focus,
.select-styles:focus {
  outline: rgb(94, 125, 143);
  box-shadow: 0px 0px 1px 3px rgba(221, 199, 127, 0.685);
}

.btn-reset:focus {
  outline: white;
  box-shadow: 0px 0px 7px 0px rgb(19, 165, 190);
}

.btn-reset:hover {
  background-color: #e9e9e9;
}

/* DEKSTOP VERSION */

@media screen and (min-width: 768px) {

  .filters-container {
    position: relative;
    top: 30px;
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    height: 15vh;
    border-radius: 5px;
    background-color: #00296b;
    box-shadow: 0px 7px 9px 0px rgb(37, 126, 248, 0.2);
  }

  .date-container-from, .date-container-to {
    position: relative;
    width: 17%;
    height: 7vh;
  }

  .date-styles {
    width: 100%;
    height: 7vh;
    padding: 10px 10px 10px 60px;
    border: none;
    border-radius: 5px;
    background-color: rgb(255, 255, 255, 0.3);
    font-size: 16px;
    transition: all 0.3s ease-out;
    -webkit-appearance: button;
  }

  .select-container {
    position: relative;
    width: 17%;
    height: 7vh;
  }

  .btn-reset {
    width: 7%;
    height: 7vh;
    border: none;
    border-radius: 5px;
    background-color: #fdc500;
    font-size: 14px;
    transition: all 0.2s ease-out;
    cursor: pointer;
  }  

}

/* MOBILE VERSION */

@media screen and (min-width: 320px) and (max-width: 767px) {

  .filters-container {
    position: relative;
    top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    height: 60vh;
    border-radius: 5px;
    background-color: #00296b;
    box-shadow: 0px 7px 9px 0px rgb(37, 126, 248, 0.2);
  }

  .date-container-from, .date-container-to {
    position: relative;
    width: 80%;
    height: 7vh;
  }

  .date-styles {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 40px;
    border-radius: 5px;
    border: none;
    background-color: rgb(255, 255, 255, 0.3);
    font-size: 16px;
    transition: all 0.1s ease-out;
    cursor: pointer;
  }

  .select-container {
    width: 80%;
    height: 7vh;
  }

  .btn-reset {
    width: 40%;
    height: 7vh;
    border: none;
    border-radius: 5px;
    background-color: #fdc500;
    font-size: 14px;
    transition: all 0.2s ease-out;
    cursor: pointer;
  }  

}

