.modal-message-body {
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #9b9b9b93;
  z-index: 2;
}

.modal-message-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 45vh;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  background-color: #00296bf0;
  box-shadow: 1px 2px 18px 0px rgba(50, 50, 50, 0.23);
  z-index: 2;
}

.btn-modal {
  margin-top: 20px;
}
